import PageSection from '../../components/UI/PageSection/PageSection'
import PageSectionHeader from '../../components/UI/PageSection/PageSectionHeader'
import PageSectionWrapper from '../../components/UI/PageSection/PageSectionWrapper'
import Page from '../Page'
import PortfolioPageItem from './Item/PortfolioPageItem'
import PortfolioPageNav from './Nav/PortfolioPageNav'

import classes from './PortfolioPage.module.scss'

import { PortfolioContextProvider } from '../../components/store/portfolio-context'

import portfolio1 from '../../assets/img/portfolio/1.jpg'
import portfolio2 from '../../assets/img/portfolio/2.jpg'
import portfolio3 from '../../assets/img/portfolio/3.jpg'
import portfolio4 from '../../assets/img/portfolio/4.jpg'
import portfolio5 from '../../assets/img/portfolio/5.jpg'
import portfolio6 from '../../assets/img/portfolio/6.jpg'
import portfolio7 from '../../assets/img/portfolio/7.jpg'
import portfolio8 from '../../assets/img/portfolio/8.jpg'
import portfolio9 from '../../assets/img/portfolio/9.jpg'
import { useEffect, useState } from 'react'
import SectionLoading from '../../components/UI/SectionLoading/SectionLoading'

const portfolioItems = [
	{
		key: 1,
		title: 'Browar Hirschberger',
		text: 'A company that sells regional beers according to traditional recipes. Facebook and Instagram support.',
		keywords: 'HTML, CSS, JS, REACT, PHP',
		image: portfolio1,
		image_alt: 'browarjg.pl page screenshot',
		link: 'https://browarjg.pl',
		link_name: 'Check it out',
		type: 'C',
	},
	{
		key: 2,
		title: 'AM Services',
		text: 'A simple website for a company that recovers money for underpaid traffic\u00a0damages',
		keywords: 'HTML, CSS, JS, PHP, Facebook',
		image: portfolio2,
		image_alt: 'odszkodowania24jg.pl website screenshot',
		link: 'https://odszkodowania24jg.pl',
		link_name: 'Check it out',
		type: 'C',
	},
	{
		key: 3,
		title: 'Aqua Love',
		text: 'Swim school for babies, children and adults. Integration with HR software allows pre-registration of participants.',
		keywords: 'HTML, CSS, JS, PHP, Wordpress, Facebook',
		image: portfolio3,
		image_alt: 'aqualove.pl website screenshot',
		link: 'https://aqualove.pl',
		link_name: 'Check it out',
		type: 'C',
	},
	{
		key: 4,
		title: 'LibGDX Mobile RPG Game',
		text: 'A simple mobile RPG, it features a turn-based system and a randomly generated dungeon. The game was made as part of\u00a0an engineering thesis.',
		keywords: 'JAVA, LibGDX, PHP, MySQL',
		image: portfolio4,
		image_alt: 'RPG game screenshot',
		link: 'https://github.com/BozekWeb/android_game',
		link_name: 'Check it out',
		type: 'P',
	},
	{
		key: 5,
		title: 'Sudoku App and API',
		text: 'The generation and operation of the game runs on an API written in Java and Spring hosted on Heroku.',
		keywords: 'HTML, CSS, JS, REACT, JAVA, SPRING',
		image: portfolio5,
		image_alt: 'aqualove.pl website screenshot',
		link: '/sudoku',
		link_name: 'Check it out',
		type: 'P',
		same: true
	},
	{
		key: 6,
		title: 'Drukarnia Wielkoformatowa KAW',
		text: 'Design and development of a website for\u00a0a large format printing company. Facebook and Instagram support.',
		keywords: 'HTML, CSS, JS, REACT - upgrading',
		image: portfolio6,
		image_alt: 'reklamajg.pl page screenshot',
		link: 'https://reklamajg.pl',
		link_name: 'Check it out',
		type: 'C',
	},
	{
		key: 7,
		title: 'Exchange App',
		text: 'One of my first standalone projects based on ExchangeRate-API. The\u00a0application is used to convert all world currencies.',
		keywords: 'HTML, CSS, JS, ExhangeRate-API',
		image: portfolio7,
		image_alt: 'exchange page screenshot',
		link: 'https://exchange.bozek.website',
		link_name: 'Check it out',
		type: 'P',
	},

	{
		key: 8,
		title: 'Weather App',
		text: 'One of my first standalone projects based on the OpenWeather API. Displays\u00a0weather, forecast and air pollution information.',
		keywords: 'HTML, CSS, JS, OpenWeather-API',
		image: portfolio8,
		image_alt: 'weather page screenshot',
		link: 'https://weather.bozek.website',
		link_name: 'Check it out',
		type: 'P',
	},

	{
		key: 9,
		title: 'Weselne Czary',
		text: "My first commercial website. It is designed to showcase the Wedding Planner's offerings and also serves as\u00a0a\u00a0blog.",
		keywords: 'HTML, CSS, JS, PHP, Wordpress',
		image: portfolio9,
		image_alt: 'weselneczary.pl page screenshot',
		link: '',
		link_name: 'Site expired after 4 years in 2022',
		type: 'C',
	},
]

const render = portfolioItems.map(item => (

	<PortfolioPageItem
		key={item.key}
		title={item.title}
		text={item.text}
		keywords={item.keywords}
		image={item.image}
		image_alt={item.image_alt}
		link={item.link}
		link_name={item.link_name}
		type={item.type}
		same={item.same}
	/>
))

const PortfolioPage = () => {
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true)
		}, 1200)
	}, [])

	return (
		<Page className={classes.portfolio} pageName='PORTFOLIO'>
			<PageSection>
				{!isLoaded ? <SectionLoading /> : ''}

				<PortfolioContextProvider>
					<PageSectionHeader title='My Projects' />
					<PortfolioPageNav />

					<PageSectionWrapper className={classes.portfolio__wrapper}>{render}</PageSectionWrapper>
				</PortfolioContextProvider>
			</PageSection>
		</Page>
	)
}

export default PortfolioPage
