import { useContext } from 'react'
import SudokuContext from '../../../components/store/sudoku-context'
import classes from '../SudokuPage.module.scss'

const ChangeOverlay = () => {
	const { overlay, setOverlay, setGameDifficulty, setChangeBoard } = useContext(SudokuContext)

	const closeOverlay = () => {
		setTimeout(() => {
			setOverlay({ style: -1, value: -1 })
		}, 100)
	}

	const change = () => {
		closeOverlay()
		setGameDifficulty(overlay.value)
		setChangeBoard(true)
	}

	const difficultyButton = (
		<div className={classes['sudoku-overlay']}>
			<h4>Changing the difficulty level</h4>
			<p>If you change the difficulty level you will lose the progress you have made so far.</p>
			<div className={classes['sudoku-overlay__button']}>
				<input
					className={classes['sudoku-overlay__button-cancel']}
					type='button'
					value='Cancel'
					onClick={closeOverlay}
				/>
				<input className={classes['sudoku-overlay__button-submit']} type='button' value='Change' onClick={change} />
			</div>
		</div>
	)

	const newGameButton = (
		<div className={classes['sudoku-overlay']}>
			<h4>New game</h4>
			<p>Start a new game with the current difficulty level.</p>
			<div className={classes['sudoku-overlay__button']}>
				<input
					className={classes['sudoku-overlay__button-cancel']}
					type='button'
					value='Cancel'
					onClick={closeOverlay}
				/>
				<input className={classes['sudoku-overlay__button-submit']} type='button' value='Start' onClick={change} />
			</div>
		</div>
	)

	const lose = (
		<div className={classes['sudoku-overlay']}>
			<h4>You lost</h4>
			<p>Sorry, you made too many mistakes.</p>
			<div className={classes['sudoku-overlay__button']}>
				<input className={classes['sudoku-overlay__button-submit']} type='button' value='New game' onClick={change} />
			</div>
		</div>
	)

	const win = (
		<div className={classes['sudoku-overlay']}>
			<h4>You won</h4>
			<p>Congratulations, you have successfully solved the sudoku.</p>
			<div className={classes['sudoku-overlay__button']}>
				<input className={classes['sudoku-overlay__button-submit']} type='button' value='New game' onClick={change} />
			</div>
		</div>
	)

	return (
		<>
			{overlay.style === 1 ? difficultyButton : ''}
			{overlay.style === 2 ? newGameButton : ''}
			{overlay.style === 3 ? lose : ''}
			{overlay.style === 4 ? win : ''}
		</>
	)
}

export default ChangeOverlay
