import { Outlet } from 'react-router-dom'
import Footer from './Footer/Footer'
import Main from './Main/Main'

import Sidebar from './Sidebar/Sidebar'

import classes from './Layout.module.scss'

import MenuBorder from './MenuBorder/MenuBorder'
import Menubar from './Menubar/Menubar'
import Backdrop from './Backdrop/Backdrop'

const Layout = () => {
	return (
		<>
			<div className={classes.layout}>
				<Sidebar />
				<Menubar />
				<MenuBorder />
				<Backdrop />

				<Main>
					<Outlet />
				</Main>
				<Footer />
			</div>
		</>
	)
}

export default Layout
