import PageSection from '../../components/UI/PageSection/PageSection'
import PageSectionHeader from '../../components/UI/PageSection/PageSectionHeader'
import Page from '../Page'

import classes from './ContactPage.module.scss'
import ContactPageForm from './Form/ContactPageForm'
import ContactPageInfo from './Info/ContactPageInfo'
import ContactPageApiKey from './ApiKey/ContactPageApiKey'
import { ApiKeyContextProvider } from '../../components/store/apikey-context'

const ContactPage = () => {
	return (
		<Page className={classes.contact} pageName='CONTACT'>
			<PageSection className={classes.contact__box}>
				<PageSectionHeader title='Information' />
				<ContactPageInfo />
			</PageSection>
			<PageSection className={classes.contact__box}>
				<PageSectionHeader title='API Key for my projects' />
				<ApiKeyContextProvider>
					<ContactPageApiKey />
				</ApiKeyContextProvider>
			</PageSection>
			<PageSection className={classes.contact__form}>
				<PageSectionHeader title='Contact Form' />
				<ContactPageForm />
			</PageSection>
		</Page>
	)
}

export default ContactPage
