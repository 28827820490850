import { createContext, useState } from 'react'

const defaultNotes = {
	1: false,
	2: false,
	3: false,
	4: false,
	5: false,
	6: false,
	7: false,
	8: false,
	9: false,
}

const defaultNotesArray = []
for (let i = 0; i <= 80; i++) {
	defaultNotesArray[i] = defaultNotes
}

const SudokuContext = createContext({
	sudokuBoard: [],
	setSudokuBoard: array => {},
	sudokuBoardSolved: [],
	setSudokuBoardSolved: array => {},
	updateSudokuCell: object => {},

	activeItem: {},
	setActiveItem: object => {},

	errorArray: [],
	setErrorArray: array => {},

	notes: [],
	updateNotesInCell: (index, number) => {},
	resetNotes: () => {},
	clearNotes: () => {},

	gameMode: undefined,
	setGameMode: number => {},
	updateGame: undefined,
	setUpdateGame: boolean => {},

	error: undefined,
	setError: boolean => {},
	errorNumber: undefined,
	setErrorNumber: number => {},
	resetErrorNumber: () => {},

	gameDifficulty: undefined,
	setGameDifficulty: number => {},

	changeBoard: undefined,
	setChangeBoard: boolean => {},

	overlay: undefined,
	setOverlay: array => {},

	empty: undefined,
	setEmpty: () => {},
	resetEmpty: () => {},
})

export const SudokuContextProvider = props => {
	const [sudokuBoard, setSudokuBoard] = useState([])
	const [sudokuBoardSolved, setSudokuBoardSolved] = useState([])
	const [activeItem, setActiveItem] = useState({ index: -1, value: -1, x: -1, y: -1, region: -1, edit: false })
	const [errorArray, setErrorArray] = useState([])
	const [notes, setNotes] = useState(defaultNotesArray)
	const [gameMode, setGameMode] = useState(1)
	const [updateGame, setUpdateGame] = useState(false)
	const [error, setError] = useState(false)
	const [errorNumber, setErrorNumber] = useState(0)
	const [gameDifficulty, setGameDifficulty] = useState('0')
	const [changeBoard, setChangeBoard] = useState(true)
	const [overlay, setOverlay] = useState({ style: -1, value: -1 })
	const [empty, setEmpty] = useState(0)

	const setSudokuBoardController = array => setSudokuBoard(array)
	const setSudokuBoardSolvedController = array => setSudokuBoardSolved(array)
	const setActiveItemController = object => setActiveItem(object)

	const setErrorArrayController = array => setErrorArray(array)
	const setChangeBoardController = boolean => setChangeBoard(boolean)
	const setGameDifficultyController = number => setGameDifficulty(number)
	const setOverlayController = boolean => setOverlay(boolean)

	const updateNotesInCell = (cellIndex, cellValue) => {
		const array = notes.map((note, index) => {
			if (index === cellIndex) {
				if (note[cellValue]) {
					return {
						...note,
						[cellValue]: false,
					}
				} else {
					return {
						...note,
						[cellValue]: true,
					}
				}
			} else {
				return note
			}
		})

		setNotes(array)
	}

	const setEmptyController = () => setEmpty(empty - 1)
	const resetEmptyController = number => setEmpty(number)

	const resetNotes = index => {
		const array = notes.map((note, i) => {
			if (i === index) {
				return {
					defaultNotes,
				}
			} else {
				return note
			}
		})

		setNotes(array)
	}

	const clearNotes = () => {
		setNotes(defaultNotesArray)
	}

	const updateSudokuCell = object => {
		const updateArray = sudokuBoard.map(obj => {
			if (obj.index === object.index) {
				return object
			} else {
				return obj
			}
		})

		setSudokuBoard(updateArray)
	}

	const setGameModeController = number => setGameMode(number)
	const setUpdateGameController = boolean => setUpdateGame(boolean)
	const setErrorController = boolean => setError(boolean)
	const setErrorNumberController = () => setErrorNumber(errorNumber + 1)
	const resetErrorNumberController = () => setErrorNumber(0)

	const context = {
		sudokuBoard: sudokuBoard,
		setSudokuBoard: setSudokuBoardController,
		sudokuBoardSolved: sudokuBoardSolved,
		setSudokuBoardSolved: setSudokuBoardSolvedController,
		updateSudokuCell: updateSudokuCell,
		activeItem: activeItem,
		setActiveItem: setActiveItemController,
		errorArray: errorArray,
		setErrorArray: setErrorArrayController,
		notes: notes,
		updateNotesInCell: updateNotesInCell,
		resetNotes: resetNotes,
		clearNotes: clearNotes,
		gameMode: gameMode,
		setGameMode: setGameModeController,
		updateGame: updateGame,
		setUpdateGame: setUpdateGameController,
		error: error,
		setError: setErrorController,
		errorNumber: errorNumber,
		setErrorNumber: setErrorNumberController,
		resetErrorNumber: resetErrorNumberController,
		gameDifficulty: gameDifficulty,
		setGameDifficulty: setGameDifficultyController,
		changeBoard: changeBoard,
		setChangeBoard: setChangeBoardController,
		overlay: overlay,
		setOverlay: setOverlayController,
		empty: empty,
		setEmpty: setEmptyController,
		resetEmpty: resetEmptyController,
	}

	return <SudokuContext.Provider value={context}>{props.children}</SudokuContext.Provider>
}

export default SudokuContext
