import { useContext, useEffect, useState } from 'react'
import SudokuContext from '../../../components/store/sudoku-context'
import classes from '../SudokuPage.module.scss'

const defaultClass = classes['sudoku__difficulty-levels-item']

const SudokuDifficultyLevel = props => {
	const { gameDifficulty, setOverlay } = useContext(SudokuContext)
	const [cName, setCName] = useState(defaultClass)

	useEffect(() => {
		if (gameDifficulty !== props.level) {
			setCName(defaultClass)
		} else {
			setCName(`${defaultClass} ${classes['sudoku__difficulty-levels-item--active']}`)
		}
	}, [gameDifficulty, props.level])

	const changeLevel = () => {
		if(props.level === gameDifficulty){
			return
		}

		setOverlay({style: 1, value: props.level})
	}

	return (
		<p className={cName} level={props.level} onClick={changeLevel}>
			{props.name}
		</p>
	)
}

export default SudokuDifficultyLevel
