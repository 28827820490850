import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../../store/menu-context'
import classes from './Menu.module.scss'
import MenuItem from './MenuItem'

const Menu = props => {
	const menuContext = useContext(MenuContext)
	const [menuClass, setMenuClass] = useState('')

	useEffect(() => {
		if (menuContext.isMenuOpen) {
			setMenuClass(`${classes.menu} ${classes['menu--active']}`)
		} else {
			setMenuClass(classes.menu)
		}
	}, [menuContext.isMenuOpen])

	return (
		<div className={menuClass}>
			<MenuItem to='/' name='HOME' />
			<MenuItem to='/portfolio' name='PORTFOLIO' />
			<MenuItem to='/contact' name='CONTACT' />
		</div>
	)
}

export default Menu
