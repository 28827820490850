import classes from './SectionLoading.module.scss'

const SectionLoading = props => {
	return (
		<div className={classes['section-loading']}>
			<div className={classes['simple-spinner']}>
				<span></span>
			</div>
		</div>
	)
}

export default SectionLoading
