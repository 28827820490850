import { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiKeyContext from '../../../../components/store/apikey-context'

import classes from '../../ContactPage.module.scss'

const ContactPageApiRegister = () => {
	const apiContext = useContext(ApiKeyContext)

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [loadingClass, setLoadingClass] = useState(classes['contact__form-loading-off'])

	const [messageClass, setMessageClass] = useState(classes['contact__form-message-off'])
	const [messageText, setMessageText] = useState('Proszę zaznaczyć pole: "Nie\u00a0jestem\u00a0robotem"')

	const submitForm = async e => {
		e.preventDefault()
		setLoadingClass(classes['contact__form-loading'])

		if (email === '' || password === '' || name === '') {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Please complete all\u00a0fields', false, 1500)
			return
		}

		const body = {
			username: name,
			email,
			password,
		}

		const response = await fetch('https://bozek-website-center.herokuapp.com/api/user/create', {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
			},
		})

		const data = await response.json()

		if (response.status !== 200) {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage(data.message, false, 3000)
			return
		} else {
			window.localStorage.setItem('bozek-website-api-user', JSON.stringify(data))
			apiContext.setUser(data)
		}
	}

	const showMessage = async (text, flag, timeout) => {
		setMessageText(text)

		if (flag) {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--good']}`)
		} else {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--bad']}`)
		}

		setTimeout(() => {
			setMessageClass(classes['contact__box-message-off'])
			setMessageText('')
		}, timeout)
	}

	const changeForm = () => {
		apiContext.setState(2)
	}

	return (
		<div className={classes['contact__api-wrapper']}>
			<div className={messageClass}>
				<p>{messageText}</p>
			</div>

			<div className={loadingClass}>
				<div className={classes['simple-spinner']}>
					<span></span>
				</div>
			</div>

			<form className={classes['contact__api-form']} onSubmit={submitForm}>
				<div className={classes['contact__api-form-field']}>
					<div className={classes['contact__api-form-field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-user' />
					</div>

					<input
						className={classes['contact__api-form-input']}
						placeholder='Username'
						value={name}
						type='text'
						required
						onChange={e => setName(e.target.value)}
					/>
				</div>
				<div className={classes['contact__api-form-field']}>
					<div className={classes['contact__api-form-field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-at' />
					</div>

					<input
						className={classes['contact__api-form-input']}
						placeholder='Email address'
						value={email}
						type='email'
						required
						onChange={e => setEmail(e.target.value)}
					/>
				</div>

				<div className={classes['contact__api-form-field']}>
					<div className={classes['contact__api-form-field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-key' />
					</div>

					<input
						className={classes['contact__api-form-input']}
						placeholder='Password'
						value={password}
						type='password'
						required
						onChange={e => setPassword(e.target.value)}
					/>
				</div>

				<div className={classes['contact__api-form-buttons']}>
					<div className={classes['contact__api-form-text']}>
						<p>
							Already have an account? <span onClick={changeForm}>Sign in!</span>
						</p>
					</div>
					<input className={classes['contact__api-form-submit']} type='submit' value='Register API account' />
				</div>
			</form>
		</div>
	)
}

export default ContactPageApiRegister
