import { useContext, useEffect } from 'react'
import SudokuContext from '../../../components/store/sudoku-context'

const SudokuMistakes = props => {
	const { errorNumber, setOverlay, gameDifficulty, overlay } = useContext(SudokuContext)

	useEffect(() => {
		if (errorNumber >= 5 && overlay.style === -1) {
			setOverlay({ style: 3, value: gameDifficulty })
		}
	}, [errorNumber, gameDifficulty, setOverlay, overlay])

	return <p>Mistakes: {errorNumber}/5</p>
}

export default SudokuMistakes
