import { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import MenuContext from '../../../components/store/menu-context'
import classes from '../HomePage.module.scss'

const AboutCTA = () => {
	const menuContext = useContext(MenuContext)
	let history = useNavigate()

	const to = 'portfolio'

	const itemClicked = e => {
		e.preventDefault()

		menuContext.setIsMenuOpen(false)
		menuContext.setActiveItem(to)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(true)
			menuContext.setActiveItem(to)
		}, 100)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(false)
			history(to)
		}, 400)
	}

	return (
		<div className={classes['home__about-cta']}>
			<p>Are you interested in cooperation?</p>
			<p>Would you like to check out my&nbsp;projects?</p>

			<NavLink onClick={itemClicked} to={to} className={classes['home__about-cta-button']}>
				EXPLORE IT NOW
			</NavLink>
		</div>
	)
}

export default AboutCTA
