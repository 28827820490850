import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import classes from '../ContactPage.module.scss'

const ContactPageForm = () => {
	const captchaRef = useRef()

	const [captcha, setCaptcha] = useState(null)
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [message, setMessage] = useState('')

	const [loadingClass, setLoadingClass] = useState(classes['contact__form-loading-off'])

	const [messageClass, setMessageClass] = useState(classes['contact__form-message-off'])
	const [messageText, setMessageText] = useState('Proszę zaznaczyć pole: "Nie\u00a0jestem\u00a0robotem"')

	const onChange = value => {
		setCaptcha(value)
	}

	const submitForm = async e => {
		e.preventDefault()
		setLoadingClass(classes['contact__form-loading'])

		if (captcha === null) {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Please check the box: "I\u00a0am\u00a0not\u00a0a\u00a0robot".', false, 1500)
			return
		}

		if (name === '' || message === '' || email === '' || phone === '') {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Please complete all\u00a0fields', false, 1500)
			return
		}

		const body = {
			name,
			email,
			phone,
			message,
			captcha,
		}

		const response = await fetch('https://smtp.api.bozek.website', {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
			},
		})

		//Błąd połączenia
		if (response.status !== 200) {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Currently there is a problem connecting to the mail server, please try later.', false, 3000)
			return
		} else {
			const data = await response.json()

			if (data.code !== 6) {
				setLoadingClass(classes['contact__form-loading-off'])
				showMessage(data.message, false, 3000)
				captchaRef.current.props.grecaptcha.reset()
				return
			}

			setName('')
			setEmail('')
			setPhone('')
			setMessage('')

			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Message sent. Thank you!', true, 1500)
			captchaRef.current.props.grecaptcha.reset()
		}
	}

	const showMessage = async (text, flag, timeout) => {
		setMessageText(text)

		if (flag) {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--good']}`)
		} else {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--bad']}`)
		}

		setTimeout(() => {
			setMessageClass(classes['contact__box-message-off'])
			setMessageText('')
		}, timeout)
	}

	return (
		<div className={classes['contact__form-wrapper']}>
			<div className={messageClass}>
				<p>{messageText}</p>
			</div>

			<div className={loadingClass}>
				<div className={classes['simple-spinner']}>
					<span></span>
				</div>
			</div>

			<form className={classes.form} onSubmit={submitForm}>
				<div className={classes.form__field}>
					<div className={classes['form__field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-user' />
					</div>

					<input
						className={classes.form__input}
						placeholder='Company | Name'
						value={name}
						type='text'
						required
						onChange={e => setName(e.target.value)}
					/>
				</div>

				<div className={classes.form__field}>
					<div className={classes['form__field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-at' />
					</div>

					<input
						className={classes.form__input}
						placeholder='Email address'
						value={email}
						type='email'
						required
						onChange={e => setEmail(e.target.value)}
					/>
				</div>

				<div className={classes.form__field}>
					<div className={classes['form__field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-mobile-screen-button' />
					</div>

					<input
						className={classes.form__input}
						placeholder='Phone number'
						value={phone}
						type='number'
						required
						onChange={e => setPhone(e.target.value)}
					/>
				</div>

				<div className={classes.form__field}>
					<div className={classes['form__field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-envelope-open-text' />
					</div>

					<textarea
						className={`${classes.form__input} ${classes.form__textarea}`}
						placeholder='Message'
						value={message}
						required
						onChange={e => setMessage(e.target.value)}
					/>
				</div>

				<div className={classes.form__success}>
					<ReCAPTCHA ref={captchaRef} sitekey='6Lcol24kAAAAAEMp3KFGMt48U7oH-TCI4sthcAXM' onChange={onChange} />
					<input className={classes.form__submit} type='submit' value='Send a message' />
				</div>
			</form>
		</div>
	)
}

export default ContactPageForm
