import classes from './Footer.module.scss'

const Footer = props => {
	return (
		<div className={classes.footer}>
			<div className={classes.footer__wrapper}>
				<div className={classes.footer__about}>&copy; {new Date().getFullYear()} Bożek.<span>Website</span></div>

				<div className={classes.footer__info}>Let's stay connected</div>
			</div>
		</div>
	)
}

export default Footer
