import SudokuControllButtonErase from './SudokuControllButtonErase'
import SudokuControllButtonHint from './SudokuControllButtonHint'
import SudokuControllButtonNotes from './SudokuControllButtonNotes'

import classes from '../../SudokuPage.module.scss'

const SudokuControllButtons = () => {
	return (
		<div className={classes.sudoku__buttons}>
			<SudokuControllButtonNotes />
			<SudokuControllButtonErase />
			<SudokuControllButtonHint />
		</div>
	)
}

export default SudokuControllButtons
