import { createContext, useState } from 'react'

const MenuContext = createContext({
	isSideOpen: undefined,
	setIsSideOpen: boolean => {},
	isMenuOpen: undefined,
	setIsMenuOpen: boolean => {},
	activeItem: undefined,
	setActiveItem: string => {},
	isPageSwap: undefined,
	setIsPageSwap: boolean => {},
})

export const MenuContextProvider = props => {
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [isSideOpen, setIsSideOpen] = useState(false)
	const [isPageSwap, setIsPageSwap] = useState(false)
	const [activeItem, setActiveItem] = useState('')

	const setIsSideOpenController = boolean => setIsSideOpen(boolean)

	const setIsMenuOpenController = boolean => setIsMenuOpen(boolean)

	const setIsPageSwapController = boolean => setIsPageSwap(boolean)

	const setActiveItemController = string => setActiveItem(string)

	const context = {
		isSideOpen: isSideOpen,
		setIsSideOpen: setIsSideOpenController,
		isMenuOpen: isMenuOpen,
		setIsMenuOpen: setIsMenuOpenController,
		activeItem: activeItem,
		setActiveItem: setActiveItemController,
		isPageSwap: isPageSwap,
		setIsPageSwap: setIsPageSwapController,
	}

	return <MenuContext.Provider value={context}>{props.children}</MenuContext.Provider>
}

export default MenuContext
