import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import SudokuContext from '../../../../components/store/sudoku-context'
import classes from '../../SudokuPage.module.scss'

const defaultClass = classes['sudoku__buttons-item']

const SudokuControllButtonHint = () => {
	const {setActiveItem, setUpdateGame, sudokuBoard, sudokuBoardSolved } = useContext(SudokuContext)

	const [hintNumber, setHintNumber] = useState(3)
	const [cName, setCName] = useState(defaultClass)

	useEffect(() => {
		if (hintNumber === 0) {
			setCName(`${defaultClass} ${classes['sudoku__buttons-item--off']}`)
		} else {
			setCName(defaultClass)
		}
	}, [hintNumber])

	const useHint = () => {
		if (hintNumber > 0) {
			setHintNumber(hintNumber - 1)

			const array = sudokuBoard.filter(el => el.value === '')
			const rand = getRandomInt(array.length - 1)
			const correct = sudokuBoardSolved[array[rand].index]

			setActiveItem(correct)

			setUpdateGame(true)
		}
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * max)
	}

	return (
		<div className={cName} onClick={useHint}>
			<div className={classes['sudoku__buttons-item--hint']}>{hintNumber}</div>
			<FontAwesomeIcon icon='fa-regular fa-lightbulb' />
			<p>Hint</p>
		</div>
	)
}

export default SudokuControllButtonHint
