import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../store/menu-context'
import classes from './Main.module.scss'

const Main = props => {
	const menuContext = useContext(MenuContext)
	const [mainClass, setMainClass] = useState('')
	const [mainSwapClass, setMainSwapClass] = useState('')

	useEffect(() => {
		if (menuContext.isSideOpen) {
			setMainClass(`${classes.main} ${classes['main--side']}`)
		} else {
			setMainClass(classes.main)
		}
	}, [menuContext.isSideOpen])

	useEffect(() => {
		if (menuContext.isMenuOpen) {
			setMainClass(`${classes.main} ${classes['main--menu']}`)
		} else {
			setMainClass(classes.main)
		}
	}, [menuContext.isMenuOpen])

	useEffect(() => {
		if (menuContext.isPageSwap) {
			setMainSwapClass(`${classes.main__swap} ${classes['main__swap--active']}`)
		} else {
			setMainSwapClass(classes.main__swap)
		}
	}, [menuContext.isPageSwap])

	return (
		<div className={mainClass}>
			<div className={mainSwapClass}>{props.children}</div>
		</div>
	)
}

export default Main
