import classes from '../ContactPage.module.scss'

const ContactPageInfo = () => {
	return (
		<div className={classes['contact__info']}>
			<div className={classes['contact__info-box']}>
				<div className={classes['contact__info-item']}>
					<p>Name:</p>
					<p>Jakub Bożek</p>
				</div>

				<div className={classes['contact__info-item']}>
					<p>Residence:</p>
					<p>Poland</p>
				</div>

				<div className={classes['contact__info-item']}>
					<p>City:</p>
					<p>Jelenia Góra</p>
				</div>
			</div>

			<div className={classes['contact__info-box']}>
				<div className={classes['contact__info-item']}>
					<p>Email:</p>
					<p>contact@bozek.website</p>
				</div>

				<div className={classes['contact__info-item']}>
					<p>Email:</p>
					<p>bozek.code@gmail.com</p>
				</div>

				<div className={classes['contact__info-item']}>
					<p>Phone:</p>
					<p>(+48) 452-299-279</p>
				</div>
			</div>

			<div className={classes['contact__info-text']}>
				I'm glad you found your way here. If you like my designs, have any questions or want to start cooperation then
				please feel free to contact me via the contact form or directly by&nbsp;email&nbsp;or&nbsp;phone&nbsp;call.
			</div>
		</div>
	)
}

export default ContactPageInfo
