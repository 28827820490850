import { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiKeyContext from '../../../../components/store/apikey-context'
import classes from '../../ContactPage.module.scss'

const ContactPageApiLogin = () => {
	const apiContext = useContext(ApiKeyContext)

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [loadingClass, setLoadingClass] = useState(classes['contact__form-loading-off'])

	const [recoveryClass, setRecoveryClass] = useState(classes['contact__api-recovery-off'])

	const [messageClass, setMessageClass] = useState(classes['contact__form-message-off'])
	const [messageText, setMessageText] = useState('Proszę zaznaczyć pole: "Nie\u00a0jestem\u00a0robotem"')

	const submitForm = async e => {
		e.preventDefault()
		setLoadingClass(classes['contact__form-loading'])

		if (email === '' || password === '') {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Please complete all\u00a0fields', false, 1500)
			return
		}

		const body = {
			email,
			password,
		}

		const response = await fetch('https://bozek-website-center.herokuapp.com/api/user/login', {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json',
			},
		})

		const data = await response.json()

		if (response.status !== 200) {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage(data.message, false, 3000)
			return
		} else {
			window.localStorage.setItem('bozek-website-api-user', JSON.stringify(data))
			apiContext.setUser(data)
		}
	}

	const openRecovery = () => {
		setRecoveryClass(classes['contact__api-recovery'])
	}

	const closeRecovery = () => {
		setRecoveryClass(classes['contact__api-recovery-off'])
	}

	const submitRecovery = async e => {
		e.preventDefault()
		closeRecovery()

		setLoadingClass(classes['contact__form-loading'])

		if (email === '') {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Please complete all\u00a0fields', false, 1500)
			return
		}

		await fetch(`https://bozek-website-center.herokuapp.com/api/user/forgot-password?email=${email}`)

		showMessage('If an account with the specified email address exists, a password will be sent.', true, 4500)

		setLoadingClass(classes['contact__form-loading-off'])
	}

	const showMessage = async (text, flag, timeout) => {
		setMessageText(text)

		if (flag) {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--good']}`)
		} else {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--bad']}`)
		}

		setTimeout(() => {
			setMessageClass(classes['contact__box-message-off'])
			setMessageText('')
		}, timeout)
	}

	const changeForm = () => {
		apiContext.setState(1)
	}

	return (
		<div className={classes['contact__api-wrapper']}>
			<div className={recoveryClass}>
				<form className={classes['contact__api-form']} onSubmit={submitRecovery}>
					<h4>Password Recovery</h4>
					<div className={classes['contact__api-form-field']}>
						<div className={classes['contact__api-form-field-icon']}>
							<FontAwesomeIcon icon='fa-solid fa-at' />
						</div>

						<input
							className={classes['contact__api-form-input']}
							placeholder='Email address'
							value={email}
							type='email'
							required
							onChange={e => setEmail(e.target.value)}
						/>
					</div>

					<div className={classes['contact__api-recovery-buttons']}>
						<input
							className={classes['contact__api-recovery-buttons-close']}
							type='button'
							onClick={closeRecovery}
							value='Close'
						/>

						<input className={classes['contact__api-form-submit']} type='submit' value='Recovery' />
					</div>
				</form>
			</div>

			<div className={messageClass}>
				<p>{messageText}</p>
			</div>

			<div className={loadingClass}>
				<div className={classes['simple-spinner']}>
					<span></span>
				</div>
			</div>

			<form className={classes['contact__api-form']} onSubmit={submitForm}>
				<div className={classes['contact__api-form-field']}>
					<div className={classes['contact__api-form-field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-at' />
					</div>

					<input
						className={classes['contact__api-form-input']}
						placeholder='Email address'
						value={email}
						type='email'
						required
						onChange={e => setEmail(e.target.value)}
					/>
				</div>

				<div className={classes['contact__api-form-field']}>
					<div className={classes['contact__api-form-field-icon']}>
						<FontAwesomeIcon icon='fa-solid fa-key' />
					</div>

					<input
						className={classes['contact__api-form-input']}
						placeholder='Password'
						value={password}
						type='password'
						required
						onChange={e => setPassword(e.target.value)}
					/>
				</div>

				<div className={classes['contact__api-form-buttons']}>
					<div className={classes['contact__api-form-text']}>
						<p>
							Forgot your password? <span onClick={openRecovery}>Get it back!</span>
						</p>
						<p>
							Don't have an account yet? <span onClick={changeForm}>Register now!</span>
						</p>
					</div>
					<input className={classes['contact__api-form-submit']} type='submit' value='Login to API account' />
				</div>
			</form>
		</div>
	)
}

export default ContactPageApiLogin
