import { createContext, useState } from 'react'

const ApiKeyContext = createContext({
	user: undefined,
	setUser: object => {},
	state: undefined,
	setState: number => {},
})

export const ApiKeyContextProvider = props => {
	const [user, setUser] = useState({ username: '', id: '', apiKey: '' })
	const [state, setState] = useState(2)

	const setUserController = object => setUser(object)
	const setStateController = number => setState(number)

	const context = {
		user: user,
		setUser: setUserController,
		state: state,
		setState: setStateController,
	}

	return <ApiKeyContext.Provider value={context}>{props.children}</ApiKeyContext.Provider>
}

export default ApiKeyContext
