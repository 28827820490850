const URL = 'https://bozek-website-center.herokuapp.com/api'

export const generateSudoku = async difficultyLevel => {
	const generateURL = `${URL}/sudoku/generate`

	const body = {
		difficultyLevel: difficultyLevel,
		username: 'admin',
		apiKey: 'H6C76U1f6578xCgX6G2Kh3gVE',
	}

	const response = await fetch(generateURL, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	if (response.status === 403) {
		return {
			valid: false,
			data: null,
		}
	}

	let data = await response.json()

	let x = data.gameBoard

	for (let i = 0; i < x.length; i++) {
		if (x[i].value === 0) {
			x[i] = {
				...x[i],
				value: '',
			}
		}
	}

	data = {
		...data,
		gameBoard: x,
	}

	return {
		valid: true,
		data: await data,
	}
}

export const register = async (username, password, email) => {
	const registerURL = `${URL}/player/register/`

	const response = await fetch(registerURL, {
		method: 'POST',
		body: JSON.stringify({ username, password, email }),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	if (response.status === 403) {
		return {
			valid: false,
			status: response.status,
			data: null,
		}
	}

	if (response.status === 409) {
		return {
			valid: false,
			status: response.status,
			data: null,
		}
	}

	const data = await response.json()

	return {
		valid: true,
		status: response.data,
		data: await data,
	}
}
