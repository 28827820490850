import Card from '../../../UI/Card/Card'
import CardContainer from '../../../UI/Card/CardContainer'
import classes from './Side.module.scss'

const SideInfo = props => {
	return (
		<Card className={classes.side__info}>
			<CardContainer className={classes['side__info-container']}>
				<div className={classes['side__info-item']}>
					<div className={classes['side__info-item--main']}>Residence:</div>
					<div>Poland</div>
				</div>

				<div className={classes['side__info-item']}>
					<div className={classes['side__info-item--main']}>City:</div>
					<div>Jelenia Góra</div>
				</div>
			</CardContainer>
		</Card>
	)
}

export default SideInfo
