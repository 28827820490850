import SudokuControllButtons from './Buttons/SudokuControllButtons'
import SudokuControllNewGame from './NewGame/SudokuControllNewGame'
import SudokuControllNumber from './Number/SudokuControllNumber'

import classes from '../SudokuPage.module.scss'

const SudokuControll = () => {
	return (
		<div className={classes.sudoku__controll}>
			<SudokuControllButtons />
			<SudokuControllNumber />
			<SudokuControllNewGame />
		</div>
	)
}

export default SudokuControll
