import classes from '../HomePage.module.scss'

import PageSection from '../../../components/UI/PageSection/PageSection'
import PageSectionWrapper from '../../../components/UI/PageSection/PageSectionWrapper'
import ServicesItem from './ServicesItem'
import PageSectionHeader from '../../../components/UI/PageSection/PageSectionHeader'

const servicesItems = [
	{
		key: 1,
		title: 'Website Development',
		text: 'Full customer service from the creation of the graphic design, purchase of the domain and hosting to finally get a unique and functional website.',
		icon: 'fa-solid fa-laptop-code',
	},
	{
		key: 2,
		title: 'CMS Wordpress',
		text: 'Buying or preparing a great theme for the most popular CMS on the market - Wordpress. Assistance in configuration and training on how to use it.',
		icon: 'fa-brands fa-wordpress-simple',
	},
	{
		key: 3,
		title: 'Convert design to web page',
		text: "Do you have a ready graphic design of the website or its outline? Great, I'll help turn it into a fully functional website that will delight all your visitors.",
		icon: 'fa-regular fa-image',
	},
	{
		key: 4,
		title: 'Adding new features to the website ',
		text: 'If your website suffers from a lack of useful features, such as integration with social media or a newsletter. I will gladly help you add them.',
		icon: 'fa-solid fa-puzzle-piece',
	},
	{
		key: 5,
		title: 'Social media assistance',
		text: 'Assistance in setting up and running social media - Facebook and Instagram, to increase company visibility and profits.',
		icon: 'fa-solid fa-thumbs-up',
	},
	{
		key: 6,
		title: 'IT Support',
		text: 'Diagnosis and repair of computer hardware, assistance with the selection of appropriate equipment for the company, networks and micro servers.',
		icon: 'fa-solid fa-screwdriver-wrench',
	},
]

const render = servicesItems.map(item => (
	<ServicesItem key={item.key} title={item.title} icon={item.icon}>
		{item.text}
	</ServicesItem>
))

const HomePageServices = () => {
	return (
		<PageSection className={classes.home__services}>
			<PageSectionHeader title='My Services' />
			<PageSectionWrapper className={classes['home__services-wrapper']}>{render}</PageSectionWrapper>
		</PageSection>
	)
}

export default HomePageServices
