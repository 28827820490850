import classes from '../../SudokuPage.module.scss'
import SudokuControllNumberItem from './SudokuControllNumberItem'

const SudokuControllNumber = () => {
	return (
		<div className={classes.sudoku__numbers}>
			<SudokuControllNumberItem value={1} />
			<SudokuControllNumberItem value={2} />
			<SudokuControllNumberItem value={3} />
			<SudokuControllNumberItem value={4} />
			<SudokuControllNumberItem value={5} />
			<SudokuControllNumberItem value={6} />
			<SudokuControllNumberItem value={7} />
			<SudokuControllNumberItem value={8} />
			<SudokuControllNumberItem value={9} />
		</div>
	)
}

export default SudokuControllNumber
