import classes from '../SudokuPage.module.scss'
import SudokuDifficultyLevel from './SudokuDifficultyLevel'

const SudokuDifficulty = () => {
	return (
		<div className={classes.sudoku__difficulty}>
			<div className={classes['sudoku__difficulty-header']}>Difficulty:</div>
			<div className={classes['sudoku__difficulty-levels']}>
				<SudokuDifficultyLevel name='Easy' level='0' />
				<SudokuDifficultyLevel name='Medium' level='1' />
				<SudokuDifficultyLevel name='Hard' level='2' />
				<SudokuDifficultyLevel name='Expert' level='3' />
			</div>
		</div>
	)
}

export default SudokuDifficulty
