import { HashRouter, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage/HomePage'
import Layout from './components/layout/Layout'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faEllipsisVertical,
	faLaptopCode,
	faScrewdriverWrench,
	faThumbsUp,
	faPuzzlePiece,
	faArrowRight,
	faUser,
	faAt,
	faMobileScreenButton,
	faEnvelopeOpenText,
	faEraser,
	faKey,
	faStop
} from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faGithub, faLinkedin, faWordpressSimple } from '@fortawesome/free-brands-svg-icons'
import { faClock, faImage, faLightbulb, faPenToSquare } from '@fortawesome/free-regular-svg-icons'

import ContactPage from './pages/ContactPage/ContactPage'
import PortfolioPage from './pages/PortfolioPage/PortfolioPage'
import ScrollToTop from './components/utils/ScrollToTop'
import { useEffect, useState } from 'react'
import PageLoading from './components/UI/PageLoading/PageLoading'
import SudokuPage from './pages/SudokuPage/SudokuPage'

library.add(
	faEllipsisVertical,
	faLaptopCode,
	faWordpressSimple,
	faImage,
	faScrewdriverWrench,
	faThumbsUp,
	faPuzzlePiece,
	faArrowRight,
	faUser,
	faAt,
	faMobileScreenButton,
	faEnvelopeOpenText,
	faDiscord,
	faLinkedin,
	faGithub,
	faPenToSquare,
	faEraser,
	faLightbulb,
	faKey,
	faStop,
	faClock
)

function App() {
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true)
		}, 1500)
	}, [])

	return (
		<>
			{!isLoaded ? <PageLoading /> : ''}

			<HashRouter>
				<ScrollToTop />
				<Routes>
					<Route exact path='/' element={<Layout />}>
						<Route index element={<HomePage />} />
						<Route path='portfolio' element={<PortfolioPage />} />
						<Route path='contact' element={<ContactPage />} />
						<Route path='sudoku' element={<SudokuPage />} />
						<Route path='*' element={<HomePage />} />
					</Route>
				</Routes>
			</HashRouter>
		</>
	)
}

export default App
