import classes from '../SudokuPage.module.scss'

const SudokuNotes = ({ notes }) => {
	return (
		<div className={classes['sudoku-cell__notes']}>
			<p>{notes[1] === true ? 1 : ''}</p>
			<p>{notes[2] === true ? 2 : ''}</p>
			<p>{notes[3] === true ? 3 : ''}</p>
			<p>{notes[4] === true ? 4 : ''}</p>
			<p>{notes[5] === true ? 5 : ''}</p>
			<p>{notes[6] === true ? 6 : ''}</p>
			<p>{notes[7] === true ? 7 : ''}</p>
			<p>{notes[8] === true ? 8 : ''}</p>
			<p>{notes[9] === true ? 9 : ''}</p>
		</div>
	)
}

export default SudokuNotes
