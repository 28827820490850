import { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import MenuContext from '../../../store/menu-context'
import classes from './Menu.module.scss'

const MenuItem = props => {
	const menuContext = useContext(MenuContext)
	const [menuItemClass, setMenuItemClass] = useState('')

	let history = useNavigate()



	useEffect(() => {
		if (menuContext.activeItem === props.name) {
			setMenuItemClass(`${classes.menu__item} ${classes['menu__item--active']}`)
		} else {
			setMenuItemClass(classes.menu__item)
		}
	}, [menuContext.activeItem, props.name])

	const itemClicked = e => {
		e.preventDefault()

		if(props.name === menuContext.activeItem){
			menuContext.setIsMenuOpen(false)
			return
		}

		menuContext.setIsMenuOpen(false)
		menuContext.setActiveItem(props.name)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(true)
		}, 100)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(false)
			history(props.to)
		}, 400)
	}

	return (
		<NavLink className={menuItemClass} to={props.to} onClick={itemClicked}>
			{props.name}
		</NavLink>
	)
}

export default MenuItem
