import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import SudokuContext from '../../../../components/store/sudoku-context'
import classes from '../../SudokuPage.module.scss'

const SudokuControllButtonErase = () => {
	const { activeItem, setActiveItem, setUpdateGame, resetNotes } = useContext(SudokuContext)

	const eraseNumber = () => {
		if (activeItem.index !== -1) {
			document.getElementById(activeItem.index).focus()

			if (activeItem.value === '') {
				resetNotes(activeItem.index)
				return
			}

			if (activeItem.edit) {
				setActiveItem(activeItem => {
					return {
						...activeItem,
						value: '',
					}
				})

				setUpdateGame(true)
			}
		}
	}

	return (
		<div className={classes['sudoku__buttons-item']} onClick={eraseNumber}>
			<FontAwesomeIcon icon='fa-solid fa-eraser' />
			<p>Erase</p>
		</div>
	)
}

export default SudokuControllButtonErase
