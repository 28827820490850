import Card from '../../../UI/Card/Card'
import CardContainer from '../../../UI/Card/CardContainer'
import CardHeader from '../../../UI/Card/CardHeader'
import ProgressBar from '../../../UI/ProgressBar/ProgressBar'
import classes from './Side.module.scss'

const SideCoding = props => {
	return (
		<Card className={classes.side__coding}>
			<CardHeader title='Programming Skills' />
			<CardContainer className={classes['side__coding-container']}>
				<ProgressBar p='80' name='HTML' />
				<ProgressBar p='80' name='CSS / scss' />
				<ProgressBar p='65' name='Java' />
				<ProgressBar p='45' name='Spring' />
				<ProgressBar p='60' name='JS' />
				<ProgressBar p='45' name='React' />
			</CardContainer>
		</Card>
	)
}

export default SideCoding
