import { useContext } from 'react'
import MenuContext from '../../store/menu-context'
import classes from './MenuBorder.module.scss'

const MenuBorder = props => {
	const menuContext = useContext(MenuContext)

	const closeBars = () => {
		menuContext.setIsMenuOpen(false)
		menuContext.setIsSideOpen(false)
	}

	return <div className={classes['menu-border']} onClick={closeBars}></div>
}

export default MenuBorder
