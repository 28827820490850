import classes from './RadialProgressBar.module.scss'

const RadialProgressBar = props => {
	let main = classes['pie-wrapper']
	let p = classes[`progress-${props.p}`]
	let cName = `${main} ${p}`



	return (
		<div className={`${cName}`}>
			<span className={classes.label}>
				{props.p}<span className={classes.smaller}>%</span>
			</span>
			<div className={classes.pie}>
				<div className={`${classes['left-side']} ${classes['half-circle']} `}></div>
				<div className={`${classes['right-side']} ${classes['half-circle']} `}></div>
			</div>
			<div className={classes.shadow}></div>
		</div>
	)
}

export default RadialProgressBar
