import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../store/menu-context'
import Menu from './Menu/Menu'
import classes from './Menubar.module.scss'
import MenubarActiveItem from './MenubarActiveItem'
import MenubarButton from './MenubarButton'
import MenubarContainer from './MenubarContainer'

const Menubar = props => {
	const menuContext = useContext(MenuContext)
	const [menubarClass, setMenubarClass] = useState('')

	useEffect(() => {
		if (menuContext.isMenuOpen) {
			setMenubarClass(`${classes.menubar} ${classes['menubar--active']}`)
		} else {
			setMenubarClass(classes.menubar)
		}
	}, [menuContext.isMenuOpen])

	return (
		<div className={menubarClass}>
			<MenubarButton />
			<MenubarContainer>
				<MenubarActiveItem />
				<Menu />
			</MenubarContainer>
		</div>
	)
}

export default Menubar
