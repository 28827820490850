import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../store/menu-context'
import classes from './Menubar.module.scss'

const MenubarButton = props => {
	const menuContext = useContext(MenuContext)
	const [menuButtonClass, setMenuButtonClass] = useState('')

	useEffect(() => {
		if (menuContext.isMenuOpen) {
			setMenuButtonClass(`${classes.menubar__button} ${classes['menubar__button--active']}`)
		} else {
			setMenuButtonClass(classes.menubar__button)
		}
	}, [menuContext.isMenuOpen])

    useEffect(() => {
		if (menuContext.isSideOpen) {
			setMenuButtonClass(`${classes.menubar__button} ${classes['menubar__button--hide']}`)
		} else {
			setMenuButtonClass(classes.menubar__button)
		}
	}, [menuContext.isSideOpen])

	const openMenuBar = () => {
		if (menuContext.isMenuOpen) {
			menuContext.setIsMenuOpen(false)
		} else {
			menuContext.setIsMenuOpen(true)
		}
	}

	return (
		<div className={menuButtonClass}>
			<span onClick={openMenuBar}>
				<div></div>
				<div></div>
				<div></div>
			</span>
		</div>
	)
}

export default MenubarButton
