import { SudokuContextProvider } from '../../components/store/sudoku-context'
import PageSection from '../../components/UI/PageSection/PageSection'
import PageSectionHeader from '../../components/UI/PageSection/PageSectionHeader'
import PageSectionWrapper from '../../components/UI/PageSection/PageSectionWrapper'
import Page from '../Page'
import SudokuBoard from './Board/SudokuBoard'
import SudokuControll from './Controll/SudokuControll'
import SudokuDifficulty from './Difficulty/SudokuDifficulty'
import SudokuMistakes from './Mistakes/SudokuMistakes'
import ChangeOverlay from './Overlay/ChangeOverlay'
import LoadingOverlay from './Overlay/LoadingOverlay'

import classes from './SudokuPage.module.scss'
import SudokuTimer from './Timer/SudokuTimer'

const SudokuPage = () => {
	return (
		<Page className={classes.sudoku} pageName='Sudoku'>
			<PageSection className={classes.sudoku__section}>
				<PageSectionHeader title='Sudoku App' />
				<SudokuContextProvider>
					<LoadingOverlay />
					<ChangeOverlay />

					<PageSectionWrapper className={classes.sudoku__wrapper}>
						<div className={classes.sudoku__header}>
							<SudokuDifficulty />
							<div className={classes.sudoku__information}>
								<SudokuTimer />
								<SudokuMistakes />
							</div>
						</div>

						<div className={classes.sudoku__body}>
							<SudokuBoard />
							<SudokuControll />
						</div>
					</PageSectionWrapper>
				</SudokuContextProvider>
			</PageSection>

			<PageSection className={classes.sudoku__section}>
				<PageSectionHeader title='About the game' />
				<PageSectionWrapper className={classes['sudoku__wrapper-api']}>
					<p className={classes['sudoku__wrapper-api-paragraph']}>
						The goal of Sudoku is to fill in a 9×9 grid with digits so that each column, row, and 3×3 section contain
						the numbers between 1 to 9. At the beginning of the game, the 9×9 grid will have some of the squares filled
						in. Your job is to use logic to fill in the missing digits and complete the grid.
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						The game ends when the 5th mistake is made or the entire board is filled correctly.
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						The game has 4 levels of difficulty. The higher the level, the more starting fields will be removed.
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						The game can be operated with numbers from the keyboard or with dedicated buttons. In addition, you can
						control the active field using the arrows on the keyboard.
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						During the game you can use 3 hints and use notes to mark potential numbers. Good luck!
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>Move is incorrect if:</p>

					<div className={classes['sudoku__wrapper-api-ins']}>
						<p>- Any row contains more than one of the same number from 1 to 9,</p>
						<p>- Any column contains more than one of the same number from 1 to 9,</p>
						<p>- Any 3×3 grid contains more than one of the same number from 1 to 9</p>
					</div>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						As a logic puzzle, Sudoku is also an excellent brain game. If you play Sudoku daily, you will soon start to
						see improvements in your concentration and overall brain power.
					</p>
				</PageSectionWrapper>
			</PageSection>

			<PageSection className={classes.sudoku__section}>
				<PageSectionHeader title='Sudoku API' />

				<PageSectionWrapper className={classes['sudoku__wrapper-api']}>
					<p className={classes['sudoku__wrapper-api-paragraph']}>
						An account is required to use the API, which can be created on the <span>contact page</span>
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						API has one POST request at https://bozek-website-center.herokuapp.com/api/sudoku/generate
					</p>

					<p className={classes['sudoku__wrapper-api-paragraph']}>In the body, place an object containing:</p>

					<div className={classes['sudoku__wrapper-api-json']}>
						<p>{'{'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'difficultyLevel: 0 to 3,'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'username: from API account,'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'apiKey: from API account,'}</p>
						<p>{'}'}</p>
					</div>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						In response, you will receive an object containing:
					</p>

					<div className={classes['sudoku__wrapper-api-json']}>
						<p>{'{'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'empty: # of empty field,'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'gameBoard: array[81]'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'fullBoard: array[81] - solved'}</p>
						<p>{'}'}</p>
					</div>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						Each array contains a collection of objects with fields:
					</p>

					<div className={classes['sudoku__wrapper-api-json']}>
						<p>{'{'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'index: #[0-80],'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'value: #[0-9],'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'x: #[0-8],'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'y: #[0-8],'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'region: #[0-8],'}</p>
						<p>&nbsp;&nbsp;&nbsp;&nbsp;{'edit: boolean - enable cell edit,'}</p>
						<p>{'}'}</p>
					</div>

					<p className={classes['sudoku__wrapper-api-paragraph']}>
						I encourage you to use the API and create your own implementation of the Sudoku game. If you have any
						questions feel free to contact me using the contact form.
					</p>
				</PageSectionWrapper>
			</PageSection>
		</Page>
	)
}

export default SudokuPage
