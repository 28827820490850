import classes from '../HomePage.module.scss'
import AboutVideo from './AboutVideo'
import AboutProverb from './AboutProverb'
import AboutText from './AboutText'
import AboutCTA from './AboutCTA'
import PageSection from '../../../components/UI/PageSection/PageSection'
import PageSectionWrapper from '../../../components/UI/PageSection/PageSectionWrapper'

const HomePageAbout = () => {
	return (
		<PageSection className={classes.home__about}>
			<AboutVideo />
			<div className={classes['home__about-content']}>
				<AboutProverb />
				<PageSectionWrapper>
					<AboutText />
					<AboutCTA />
				</PageSectionWrapper>
			</div>
		</PageSection>
	)
}

export default HomePageAbout
