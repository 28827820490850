import Card from '../../../UI/Card/Card'
import CardContainer from '../../../UI/Card/CardContainer'
import CardHeader from '../../../UI/Card/CardHeader'
import RadialProgressBar from '../../../UI/RadialProgressBar/RadialProgressBar'
import classes from './Side.module.scss'

const SideLanguages = props => {
	return (
		<Card className={classes.side__languages}>
			<CardHeader title='Languages' />
			<CardContainer className={classes['side__languages-container']}>
				<div className={classes['side__languages-progress']}>
					<RadialProgressBar p='100' />
					<p>Polish</p>
				</div>

				<div className={classes['side__languages-progress']}>
					<RadialProgressBar p='65' />
					<p>English</p>
				</div>

				<div className={classes['side__languages-progress']}>
					<RadialProgressBar p='10' />
					<p>German</p>
				</div>
			</CardContainer>
		</Card>
	)
}

export default SideLanguages
