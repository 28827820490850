import { NavLink, useNavigate } from 'react-router-dom'
import PageSectionItem from '../../../components/UI/PageSection/PageSectionItem'
import classes from '../HomePage.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import MenuContext from '../../../components/store/menu-context'

const ServicesItem = props => {
	const menuContext = useContext(MenuContext)
	let history = useNavigate()

	const to = 'contact'

	const itemClicked = e => {
		e.preventDefault()

		menuContext.setIsMenuOpen(false)
		menuContext.setActiveItem(to)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(true)
		}, 100)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(false)
			history(to)
		}, 400)
	}

	return (
		<PageSectionItem className={classes['home__services-item']}>
			<div className={classes['home__services-item-header']}>
				<h3>{props.title}</h3>
			</div>

			<div className={classes['home__services-item-text']}>{props.children}</div>

			<NavLink to={to} onClick={itemClicked} className={classes['home__services-item-link']}>
				Contact now
			</NavLink>
			<FontAwesomeIcon className={classes.icon} icon={props.icon} />
		</PageSectionItem>
	)
}

export default ServicesItem
