import PageSectionItem from '../../../components/UI/PageSection/PageSectionItem'
import classes from '../HomePage.module.scss'

const AboutProverb = () => {
	return (
		<div className={classes['home__about-proverb']}>
			<PageSectionItem className={classes['home__about-proverb-item']}>
				"Make it <span>WORK</span>, make&nbsp;it&nbsp;<span>RIGHT</span>, make&nbsp;it&nbsp;<span>FAST</span>"
			</PageSectionItem>
		</div>
	)
}

export default AboutProverb
