import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../store/menu-context'
import classes from './Menubar.module.scss'

const MenubarActiveItem = props => {
	const menuContext = useContext(MenuContext)
    const [infoClass, setInfoClass] = useState('')

    useEffect(() => {
		if (menuContext.isMenuOpen) {
			setInfoClass(`${classes.menubar__info} ${classes['menubar__info--active']}`)
		} else {
			setInfoClass(classes.menubar__info)
		}
	}, [menuContext.isMenuOpen])

	return <div className={infoClass}>{menuContext.activeItem}</div>
}

export default MenubarActiveItem
