import Card from '../../../UI/Card/Card'
import CardContainer from '../../../UI/Card/CardContainer'
import CardHeader from '../../../UI/Card/CardHeader'

import classes from './Side.module.scss'

const SideKnowledge = props => {
	return (
		<Card className={classes.side__knowledge}>
			<CardHeader title='Knowledge' />
			<CardContainer className={classes['side__knowledge-container']}>
				<div>Wordpress</div>
				<div>Bootstrap</div>
				<div>JUnit, Mockito</div>
				<div>Adobe Photoshop</div>
				<div>Affinity Software</div>
				<div>WCAG 2.1</div>
			</CardContainer>
		</Card>
	)
}

export default SideKnowledge
