import classes from '../ContactPage.module.scss'

import { useContext, useEffect, useState } from 'react'
import ApiKeyContext from '../../../components/store/apikey-context'
import ContactPageApiForm from './Form/ContactPageApiForm'
import ContactPageApiInformation from './Information/ContactPageApiInformation'

const ContactPageApiKey = () => {
	const apiContext = useContext(ApiKeyContext)
	const [content, setContent] = useState('')

 
	useEffect(() => {
		if (apiContext.user.username === '') {
			if (localStorage.getItem('bozek-website-api-user') !== null) {
				const storage = JSON.parse(localStorage.getItem('bozek-website-api-user'))
				apiContext.setUser(storage)
				console.log('object - created')
			}
		}
	}, [apiContext])

	useEffect(() => {
		if (apiContext.user.username === '') {
			setContent(<ContactPageApiForm />)
		} else {
			setContent(<ContactPageApiInformation />)
		}
	}, [apiContext.user])

	return <div className={classes['contact__api']}>{content}</div>
}

export default ContactPageApiKey
