import { useContext, useEffect, useState } from 'react'
import SudokuContext from '../../../components/store/sudoku-context'
import classes from '../SudokuPage.module.scss'

const LoadingOverlay = props => {
	const { changeBoard } = useContext(SudokuContext)
	const [load, setLoad] = useState(false)

	useEffect(() => {
		setLoad(true)

		setTimeout(() => {
			setLoad(false)
		}, 2000)
	}, [changeBoard])

	const content = (
		<div className={classes['sudoku-loading']}>
			<div className={classes['simple-spinner']}>
				<span></span>
			</div>
		</div>
	)

	return <>{load ? content : ''}</>
}

export default LoadingOverlay
