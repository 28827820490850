import { useContext } from 'react'
import SudokuContext from '../../../../components/store/sudoku-context'
import classes from '../../SudokuPage.module.scss'


const SudokuControllNewGame = () => {
	const {gameDifficulty, setOverlay} = useContext(SudokuContext)

	const newGame = () => {
		setOverlay({style: 2, value: gameDifficulty})
	}

	return (
		<div className={classes.sudoku__newgame} onClick={newGame}>
			<p>New Game</p>
		</div>
	)
}

export default SudokuControllNewGame
