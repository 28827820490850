import { useContext, useEffect } from 'react'
import SudokuContext from '../../../components/store/sudoku-context'
import { generateSudoku } from '../../../components/utils/rest-controller'
import SudokuCell from '../Cell/SudokuCell'
import classes from '../SudokuPage.module.scss'

const SudokuBoard = () => {
	const {
		sudokuBoard,
		setSudokuBoard,
		clearNotes,
		setSudokuBoardSolved,
		gameDifficulty,
		changeBoard,
		setChangeBoard,
		resetErrorNumber,
		setActiveItem,
		setError,
		setErrorArray,
		setGameMode,
		resetEmpty
	} = useContext(SudokuContext)

	useEffect(() => {
		const generate = async () => {
			const response = await generateSudoku(gameDifficulty)

			if (!response.valid) {
				console.log('Something gone wrong')
				return
			}

			setSudokuBoardSolved(response.data.fullBoard)
			setSudokuBoard(response.data.gameBoard)
			resetEmpty(response.data.empty)
		}

		if (changeBoard) {
			setSudokuBoard([])
			setSudokuBoardSolved([])
			clearNotes()
			resetErrorNumber(0)
			setActiveItem({ index: -1, value: -1, x: -1, y: -1, region: -1, edit: false })
			setError(false)
			setErrorArray([])
			setChangeBoard(false)
			setGameMode(1)
			resetEmpty(0)
			generate()
		}
	}, [setSudokuBoard, setSudokuBoardSolved, changeBoard, setChangeBoard, clearNotes, gameDifficulty, resetEmpty, resetErrorNumber, setActiveItem, setError, setGameMode, setErrorArray])

	return (
		<div className={classes['sudoku__board']}>
			{sudokuBoard.map(item => {
				return (
					<SudokuCell
						key={item.index}
						value={item.value}
						x={item.x}
						y={item.y}
						region={item.region}
						index={item.index}
						edit={item.edit}
					/>
				)
			})}
		</div>
	)
}

export default SudokuBoard
