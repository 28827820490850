import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classes from './PageLoading.module.scss'

const PageLoading = props => {
	return (
		<div className={classes['page-loading']}>
			<div className={classes['page-loading__loader-outer']}>
				<div className={classes['page-loading__loader-inner']}>
					<FontAwesomeIcon icon='fa-solid fa-stop' />
					<FontAwesomeIcon icon='fa-solid fa-stop' />
					<FontAwesomeIcon icon='fa-solid fa-stop' />
				</div>
			</div>
			<h1>
				<span>LOADING</span>
			</h1>
		</div>
	)
}

export default PageLoading
