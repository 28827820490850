import { useContext, useEffect, useState } from 'react'

import SudokuContext from '../../../components/store/sudoku-context'
import classes from '../SudokuPage.module.scss'
import SudokuNotes from './SudokuNotes'

const SudokuCell = props => {
	const {
		activeItem,
		setActiveItem,
		sudokuBoard,
		sudokuBoardSolved,
		updateSudokuCell,
		errorArray,
		setErrorArray,
		notes,
		updateNotesInCell,
		gameMode,
		updateGame,
		setUpdateGame,
		setErrorNumber,
		error,
		setError,
		setEmpty,
		empty,
		gameDifficulty,
		setOverlay,
	} = useContext(SudokuContext)

	const [currentCell, setCurrentCell] = useState({
		value: props.value,
		x: props.x,
		y: props.y,
		region: props.region,
		index: props.index,
		edit: props.edit,
	})

	const defaultClass = `${classes['sudoku-cell']}`
	const activeClass = `${defaultClass} ${classes['sudoku-cell--active']}`
	const sameClass = `${defaultClass} ${classes['sudoku-cell--same']}`
	const sameErrorClass = `${defaultClass} ${classes['sudoku-cell--same-error']}`
	const currentClass = `${defaultClass} ${classes['sudoku-cell--current']}`
	const currentErrorClass = `${defaultClass} ${classes['sudoku-cell--current-error']}`

	const [cName, setCName] = useState(defaultClass)

	//Aktualizacja stylów
	useEffect(() => {
		if (activeItem.index === currentCell.index) {
			if (error) {
				setCName(currentErrorClass)
			} else {
				setCName(currentClass)
			}

			return
		}

		if (
			(activeItem.x === currentCell.x || activeItem.y === currentCell.y || activeItem.region === currentCell.region) &&
			(activeItem.value !== currentCell.value || currentCell.value === '')
		) {
			setCName(activeClass)
			return
		}

		if (activeItem.value === currentCell.value && currentCell.value !== '') {
			if (!error) {
				setCName(sameClass)
				return
			}

			const check = errorArray.filter(el => el.index === currentCell.index)

			if (check.length === 1) {
				setCName(sameErrorClass)
			} else {
				setCName(sameClass)
			}

			return
		}

		setCName(defaultClass)
	}, [
		activeClass,
		currentClass,
		defaultClass,
		sameClass,
		currentErrorClass,
		sameErrorClass,
		activeItem,
		currentCell,
		error,
		errorArray,
	])

	//Sudoku board cells logic
	useEffect(() => {
		if (updateGame && activeItem.index === currentCell.index) {
			//Reset game flags
			setError(false)
			setUpdateGame(false)

			//Set value of cell to null
			if (activeItem.value === '') {
				setCurrentCell({ ...activeItem, edit: true })
				setActiveItem({ ...activeItem, edit: true })
				return
			}

			//Correct value for cell
			const correctValue = sudokuBoardSolved[activeItem.index].value

			//All value duplicate
			const interestArray = sudokuBoard.filter(
				el =>
					(el.x === activeItem.x || el.y === activeItem.y || el.region === activeItem.region) &&
					el.value === activeItem.value
			)

			//Check if entered value is incorrect
			if (activeItem.value !== correctValue) {
				setCurrentCell({ ...activeItem, edit: true })
				setActiveItem({ ...activeItem, edit: true })
				setErrorNumber()
				setError(true)

				//check is duplicate isnt null
				if (interestArray.length !== 0) {
					setErrorArray(interestArray)
				}

				return
			}

			//Correct value entered, save it
			if (activeItem.value === correctValue) {
				setActiveItem({ ...activeItem, edit: false })
				setCurrentCell({ ...activeItem, edit: false })
				updateSudokuCell({ ...activeItem, edit: false })
				setEmpty()
				document.getElementById(activeItem.index).focus()

				if (empty === 1) {
					setOverlay({ style: 4, value: gameDifficulty })
				}

				return
			}
		}
	}, [
		activeItem,
		setActiveItem,
		currentCell.index,
		updateGame,
		setUpdateGame,
		error,
		setError,
		setErrorArray,
		setErrorNumber,
		sudokuBoard,
		updateSudokuCell,
		sudokuBoardSolved,
		empty,
		setEmpty,
		setOverlay,
		gameDifficulty,
	])

	//Button Clicked on active cell
	const changeCellValue = e => {
		//Save data about clicked button
		const keyCode = e.keyCode
		const keyValue = parseInt(e.key)

		//Prevent tab button on sudoku board
		if (keyCode === 9) {
			e.preventDefault()
			return
		}

		//Move up on arrow click
		if (keyCode === 38 && activeItem.x !== 0) {
			if (!error) {
				document.getElementById(activeItem.index - 9).click()
				return
			}
		}

		//Move down on arrow click
		if (keyCode === 40 && activeItem.x !== 8) {
			if (!error) {
				document.getElementById(activeItem.index + 9).click()
				return
			}
		}

		//Move left on arrow click
		if (keyCode === 37 && activeItem.y !== 0) {
			if (!error) {
				document.getElementById(activeItem.index - 1).click()
				return
			}
		}

		//Move right on arrow click
		if (keyCode === 39 && activeItem.y !== 8) {
			if (!error) {
				document.getElementById(activeItem.index + 1).click()
				return
			}
		}

		//Delete value and notes from cell
		if (keyCode === 46 || keyCode === 8) {
			if (!activeItem.edit) {
				return
			}

			setActiveItem(activeItem => {
				return {
					...activeItem,
					value: '',
				}
			})

			setUpdateGame(true)
		}

		//Enter number into cell [1:9]
		if ((keyCode >= 49 && keyCode <= 57) || (keyCode >= 97 && keyCode <= 105)) {
			//Check if cell is able to edit value
			if (!activeItem.edit) {
				return
			}

			//Add value for notes
			if (gameMode === 2) {
				updateNotesInCell(currentCell.index, keyValue)
				return
			}

			setActiveItem(activeItem => {
				return {
					...activeItem,
					value: keyValue,
				}
			})

			setUpdateGame(true)
		}
	}

	const clickCurrentItem = () => {
		if (!error) {
			setActiveItem(currentCell)
		}
	}

	return (
		<div id={props.index} tabIndex={0} onKeyDown={changeCellValue} className={cName} onClick={clickCurrentItem}>
			{currentCell.value}
			{currentCell.value === '' ? <SudokuNotes notes={notes[currentCell.index]} /> : ''}
		</div>
	)
}

export default SudokuCell
