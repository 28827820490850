import classes from '../HomePage.module.scss'
import sample from '../../../assets/video/keyboard.mp4'

const AboutVideo = () => {
	return (
		<>
			<video autoPlay muted loop>
				<source src={sample} type='video/mp4' />
			</video>
			<div className={classes['home__about-blur']} />
		</>
	)
}

export default AboutVideo
