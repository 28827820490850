import photo from '../../../../assets/img/self.jpg'
import classes from './Side.module.scss'

const SideSelf = props => {
	return (
		<div className={classes.side__self}>
			<img className={classes['side__self-photo']} src={photo} alt='Logo' />
			<div className={classes['side__self-info']}>
				<div className={classes['side__self-info--main']}>Jakub Bożek</div>
				<div className={classes['side__self-info--sub']}>Full-Stack Developer</div>
			</div>
		</div>
	)
}

export default SideSelf
