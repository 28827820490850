import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect, useContext } from 'react'
import SudokuContext from '../../../components/store/sudoku-context'

import classes from '../SudokuPage.module.scss'

function SudokuTimer() {
	const { changeBoard } = useContext(SudokuContext)
	const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 })

	const intervalRef = useRef()

	useEffect(() => {
		setTimeout(() => {
			reset()
			start()
		}, 1500)
	}, [changeBoard])

	function start() {
		
		intervalRef.current = setInterval(() => {
			setTime(prevTime => {
				const seconds = prevTime.seconds + 1
				const minutes = prevTime.minutes + Math.floor(seconds / 60)
				const hours = prevTime.hours + Math.floor(minutes / 60)
				return { hours, minutes: minutes % 60, seconds: seconds % 60 }
			})
		}, 1000)
	}

	function reset() {
		setTime({ hours: 0, minutes: 0, seconds: 0 })
		clearInterval(intervalRef.current)
	}

	return (
		<div className={classes.sudoku__timer}>
			<div>
				{`${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}:${time.seconds
					.toString()
					.padStart(2, '0')}`}

				<FontAwesomeIcon icon='fa-regular fa-clock' />
			</div>
		</div>
	)
}

export default SudokuTimer
