import { useContext, useState } from 'react'
import PortfolioContext from '../../../components/store/portfolio-context'
import classes from '../PortfolioPage.module.scss'

const PortfolioPageNav = () => {
	const portfolioContext = useContext(PortfolioContext)

	const basic = classes['portfolio__nav-item']
	const active = `${classes['portfolio__nav-item']} ${classes['portfolio__nav-item--active']}`

	const [all, setAll] = useState(active)
	const [personal, setPersonal] = useState(basic)
	const [commercial, setCommercial] = useState(basic)

	const filterAll = e => {
		portfolioContext.setActiveFilter('ALL')
		setAll(active)
		setPersonal(basic)
		setCommercial(basic)
	}

	const filterPersonal = () => {
		portfolioContext.setActiveFilter('P')
		setAll(basic)
		setPersonal(active)
		setCommercial(basic)
	}

	const filterCommerial = () => {
		portfolioContext.setActiveFilter('C')
		setAll(basic)
		setPersonal(basic)
		setCommercial(active)
	}

	return (
		<div className={classes.portfolio__nav}>
			<div className={all} onClick={filterAll}>
				All
			</div>
			<div className={commercial} onClick={filterCommerial}>
				Commercial
			</div>
			<div className={personal} onClick={filterPersonal}>
				Personal
			</div>
		</div>
	)
}

export default PortfolioPageNav
