import Page from '../Page'

import classes from './HomePage.module.scss'
import HomePageAbout from './About/HomePageAbout'
import HomePageServices from './ServiceSection/HomePageServices'

const HomePage = () => {
	return (
		<Page className={classes.home} pageName='HOME'>
			<HomePageAbout />
			<HomePageServices />
		</Page>
	)
}

export default HomePage
