import classes from './Side.module.scss'

const SideContent = props => {
	return (
		<div className={classes['side__content']}>
            {props.children}
        </div>
	)
}

export default SideContent
