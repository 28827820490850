import { useContext, useEffect, useState } from 'react'
import MenuContext from '../components/store/menu-context'
import classes from './Page.module.scss'

const Page = props => {
	const menuContext = useContext(MenuContext)
	const [onLoad, setOnLoad] = useState(true)

	useEffect(() => {
		if (onLoad) {
			menuContext.setActiveItem(props.pageName)
			menuContext.setIsMenuOpen(false)
			menuContext.setIsSideOpen(false)
			setOnLoad(false)
		}
	}, [props.pageName, menuContext, onLoad])

	const styles = classes.page + ' ' + props.className
	return <div className={styles}>{props.children}</div>
}

export default Page
