import { createContext, useState } from 'react'

const PortfolioContext = createContext({
	activeFilter: undefined,
	setActiveFilter: string => {},
})

export const PortfolioContextProvider = props => {
	const [activeFilter, setActiveFilter] = useState('ALL')

	const setActiveFilterController = string => setActiveFilter(string)

	const context = {
		activeFilter: activeFilter,
		setActiveFilter: setActiveFilterController,
	}

	return <PortfolioContext.Provider value={context}>{props.children}</PortfolioContext.Provider>
}

export default PortfolioContext
