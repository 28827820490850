import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import SudokuContext from '../../../../components/store/sudoku-context'
import classes from '../../SudokuPage.module.scss'

const defaultClass = classes['sudoku__buttons-item--notes']

const SudokuControllButtonNotes = () => {
	const { gameMode, setGameMode, activeItem, error } = useContext(SudokuContext)

	const [state, setState] = useState('OFF')
	const [cName, setCName] = useState(defaultClass)

	useEffect(() => {
		if (gameMode === 1) {
			setState('OFF')
			setCName(defaultClass)
		} else {
			setState('ON')
			setCName(`${defaultClass} ${classes['sudoku__buttons-item--active']}`)
		}
	}, [gameMode])

	const changeGameMode = () => {
		if (error) return

		if (gameMode === 1) {
			setGameMode(2)
		} else {
			setGameMode(1)
		}

		if (activeItem.index !== -1) {
			document.getElementById(activeItem.index).focus()
		}
	}

	return (
		<div className={classes['sudoku__buttons-item']} onClick={changeGameMode}>
			<div className={cName}>{state}</div>
			<FontAwesomeIcon icon='fa-regular fa-pen-to-square' />
			<p>Notes</p>
		</div>
	)
}

export default SudokuControllButtonNotes
