import { useContext, useState } from 'react'
import SudokuContext from '../../../../components/store/sudoku-context'
import classes from '../../SudokuPage.module.scss'

const defaultClass = classes['sudoku__numbers-item']

const SudokuControllNumberItem = props => {
	const { setUpdateGame, gameMode, activeItem, setActiveItem, updateNotesInCell, error } = useContext(SudokuContext)

	const [cName, setCName] = useState(defaultClass)

	const setCurrentValue = () => {
		numberClicked()

		if (activeItem.index !== -1) {
			document.getElementById(activeItem.index).focus()

			if (gameMode === 2 && !error) {
				updateNotesInCell(activeItem.index, props.value)
				return
			}

			setActiveItem(activeItem => {
				return {
					...activeItem,
					value: props.value,
				}
			})

			setUpdateGame(true)
		}
	}

	const numberClicked = () => {
		setCName(`${defaultClass} ${classes['sudoku__numbers-item--active']}`)

		setTimeout(() => {
			setCName(defaultClass)
		}, 500)
	}

	return (
		<p className={cName} onClick={setCurrentValue}>
			{props.value}
		</p>
	)
}

export default SudokuControllNumberItem
