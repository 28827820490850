import classes from '../PortfolioPage.module.scss'

import PageSectionItem from '../../../components/UI/PageSection/PageSectionItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import PortfolioContext from '../../../components/store/portfolio-context'
import { NavLink, useNavigate } from 'react-router-dom'
import MenuContext from '../../../components/store/menu-context'

const PortfolioPageItem = props => {
	const portfolioContext = useContext(PortfolioContext)
	const menuContext = useContext(MenuContext)

	const basic = classes['portfolio__item']
	const hide = `${classes['portfolio__item']} ${classes['portfolio__item--hide']}`

	const [cName, setCName] = useState('')

	
	let history = useNavigate()

	const itemClicked = e => {
		e.preventDefault()

		window.setTimeout(() => {
			menuContext.setIsPageSwap(true)
		}, 100)

		window.setTimeout(() => {
			menuContext.setIsPageSwap(false)
			history(props.link)
		}, 400)
	}

	useEffect(() => {
		if (portfolioContext.activeFilter === props.type || portfolioContext.activeFilter === 'ALL') {
			setCName(basic)
		} else {
			setCName(hide)
		}
	}, [cName, basic, hide, portfolioContext.activeFilter, props.type])

	let link = ''

	if (props.link === '') {
		link = <p className={classes['portfolio__item-link']}>{props.link_name}</p>
	} else if (props.same) {
		link = (
			<NavLink onClick={itemClicked} className={classes['portfolio__item-link']} to={props.link}>
				{props.link_name} <FontAwesomeIcon icon='fa-solid fa-arrow-right' />
			</NavLink>
		)
	} else {
		link = (
			<a target='_blank' rel='noreferrer' href={props.link} className={classes['portfolio__item-link']}>
				{props.link_name} <FontAwesomeIcon icon='fa-solid fa-arrow-right' />
			</a>
		)
	}

	return (
		<PageSectionItem className={cName}>
			<img className={classes['portfolio__item-image']} src={props.image} alt={props.image_alt} />
			<div className={classes['portfolio__item-box']}>
				<div className={classes['portfolio__item-header']}>{props.title}</div>
				<div className={classes['portfolio__item-content']}>
					<p className={classes['portfolio__item-text']}>{props.text}</p>
					<p className={classes['portfolio__item-keywords']}>{props.keywords}</p>
				</div>

				{link}
			</div>
		</PageSectionItem>
	)
}

export default PortfolioPageItem
