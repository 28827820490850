import classes from './Side.module.scss'
import SideCoding from './SideCoding'
import SideContent from './SideContent'
import SideInfo from './SideInfo'
import SideKnowledge from './SideKnowledge'
import SideLanguages from './SideLanguages'

import SideSelf from './SideSelf'
import SideShadow from './SideShadow'

const Side = props => {
	return (
		<div className={classes.side}>
			<SideSelf />
			<SideShadow />
			<SideContent>
				<SideInfo />
				<SideLanguages />
				<SideCoding />
				<SideKnowledge />
			</SideContent>
			<SideShadow />
		</div>
	)
}

export default Side
