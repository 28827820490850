import classes from '../../ContactPage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import ApiKeyContext from '../../../../components/store/apikey-context'

const ContactPageApiInformation = () => {
	const apiContext = useContext(ApiKeyContext)

	const [recoveryClass, setRecoveryClass] = useState(classes['contact__api-recovery-off'])

	const [loadingClass, setLoadingClass] = useState(classes['contact__form-loading-off'])

	const [messageClass, setMessageClass] = useState(classes['contact__form-message-off'])
	const [messageText, setMessageText] = useState('Proszę zaznaczyć pole: "Nie\u00a0jestem\u00a0robotem"')

	const [password, setPassword] = useState('')

	const logout = e => {
		e.preventDefault()
		localStorage.clear()
		apiContext.setUser({ username: '', id: '', apiKey: '' })
	}

	const openDelete = () => {
		setRecoveryClass(classes['contact__api-recovery'])
	}

	const closeDelete = () => {
		setRecoveryClass(classes['contact__api-recovery-off'])
	}

	const submitDelete = async e => {
		e.preventDefault()
		closeDelete()
		setLoadingClass(classes['contact__form-loading'])

		if (password === '') {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Please complete all\u00a0fields', false, 1500)
			return
		}

		const response = await fetch(
			`https://bozek-website-center.herokuapp.com/api/user/delete-user?id=${apiContext.user.id}&password=${password}`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		if (response.status !== 200) {
			setLoadingClass(classes['contact__form-loading-off'])
			showMessage('Password not match', false, 3000)
			return
		} else {
			localStorage.clear()
			apiContext.setUser({ username: '', id: '', apiKey: '' })
		}
	}

	const showMessage = async (text, flag, timeout) => {
		setMessageText(text)

		if (flag) {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--good']}`)
		} else {
			setMessageClass(`${classes['contact__form-message']} ${classes['contact__form-message--bad']}`)
		}

		setTimeout(() => {
			setMessageClass(classes['contact__box-message-off'])
			setMessageText('')
		}, timeout)
	}

	return (
		<div className={classes['contact__api-information']}>
			<div className={recoveryClass}>
				<form className={classes['contact__api-form']} onSubmit={submitDelete}>
					<h4>Delete API account</h4>
					<div className={classes['contact__api-form-field']}>
						<div className={classes['contact__api-form-field-icon']}>
							<FontAwesomeIcon icon='fa-solid fa-key' />
						</div>

						<input
							className={classes['contact__api-form-input']}
							placeholder='Password'
							value={password}
							type='password'
							required
							onChange={e => setPassword(e.target.value)}
						/>
					</div>

					<div className={classes['contact__api-recovery-buttons']}>
						<input
							className={classes['contact__api-recovery-buttons-close']}
							type='button'
							onClick={closeDelete}
							value='Close'
						/>

						<input
							className={classes['contact__api-recovery-buttons-delete']}
							type='submit'
							value='Permanently delete'
						/>
					</div>
				</form>
			</div>

			<div className={messageClass}>
				<p>{messageText}</p>
			</div>

			<div className={loadingClass}>
				<div className={classes['simple-spinner']}>
					<span></span>
				</div>
			</div>

			<h4>Hi, {apiContext.user.username}!</h4>
			<p>
				API key: <span>{apiContext.user.apiKey}</span>
			</p>

			<div className={classes['contact__api-information-buttons']}>
				<input className={classes['contact__api-information-log']} type='button' onClick={logout} value='Log out' />
				<input
					className={classes['contact__api-information-delete']}
					type='button'
					onClick={openDelete}
					value='Delete account'
				/>
			</div>

			<p></p>
		</div>
	)
}

export default ContactPageApiInformation
