import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../store/menu-context'
import classes from './Sidebar.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Side from './Side/Side'

const Sidebar = props => {
	const menuContext = useContext(MenuContext)

	const [sideClass, setSideClass] = useState('')
	const [sideButtonClass, setSideButtonClass] = useState('')

	useEffect(() => {
		if (menuContext.isSideOpen) {
			setSideClass(`${classes.sidebar} ${classes['sidebar--active']}`)
		} else {
			setSideClass(classes.sidebar)
		}

		if (menuContext.isMenuOpen) {
			setSideButtonClass(`${classes.sidebar__button} ${classes['sidebar__button--hide']}`)
		} else {
			setSideButtonClass(classes.sidebar__button)
		}
	}, [menuContext.isMenuOpen, menuContext.isSideOpen])

	const openSideBar = () => {
		if (menuContext.isSideOpen) {
			menuContext.setIsSideOpen(false)
		} else {
			menuContext.setIsSideOpen(true)
		}
	}

	return (
		<div className={sideClass}>
			<div className={sideButtonClass}>
				<span onClick={openSideBar}>
					<FontAwesomeIcon icon='fa-solid fa-ellipsis-vertical' />
				</span>
			</div>

			<Side />
		</div>
	)
}

export default Sidebar
