import { useContext, useEffect, useState } from 'react'
import MenuContext from '../../store/menu-context'
import classes from './Backdrop.module.scss'

const Backdrop = props => {
	const menuContext = useContext(MenuContext)

    const [clsName, setClsName] = useState('')

    useEffect(() => {
        if(menuContext.isSideOpen || menuContext.isMenuOpen){
            setClsName(`${classes.backdrop} ${classes['backdrop--active']}`)
        } else {
            setClsName(classes.backdrop)
        }
    }, [menuContext.isMenuOpen, menuContext.isSideOpen])

	const closeBars = () => {
		menuContext.setIsMenuOpen(false)
		menuContext.setIsSideOpen(false)
	}

	return <div className={clsName} onClick={closeBars}></div>
}

export default Backdrop
