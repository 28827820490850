import PageSectionItem from '../../../components/UI/PageSection/PageSectionItem'
import classes from '../HomePage.module.scss'

const AboutText = () => {
	return (
		<div className={classes['home__about-text']}>
			<PageSectionItem className={classes['home__about-text-item']}>
				Hi, I'm <span>Jakub</span>, I'm involved in creating and designing unique websites for small and medium-sized
				businesses in the local&nbsp;market.
			</PageSectionItem>

			<PageSectionItem className={classes['home__about-text-item']}>
				I'm currently looking for new challenges where I&nbsp;can expand my current skills, as well as learn new
				technology and solutions.
			</PageSectionItem>
		</div>
	)
}

export default AboutText
