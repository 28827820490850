import { useContext } from 'react'
import ApiKeyContext from '../../../../components/store/apikey-context'
import classes from '../../ContactPage.module.scss'
import ContactPageApiLogin from './LoginForm'
import ContactPageApiRegister from './RegisterForm'

const ContactPageApiForm = () => {
	const apiContext = useContext(ApiKeyContext)

	return (
		<>
			{apiContext.state === 2 ?  <ContactPageApiLogin /> : <ContactPageApiRegister />}

			<p className={classes['contact__api-box']}>
				To use the API available in my projects, log in or create an account to generate a&nbsp;key. Data is not used
				for marketing purposes. Account can be permanently deleted at&nbsp;any&nbsp;time.
			</p>
		</>
	)
}

export default ContactPageApiForm
