import classes from './ProgressBar.module.scss'

const ProgressBar = props => {
	let main = classes['skillbox']
	let p = classes[`progress-${props.p}`]
	let cName = `${main} ${p}`

	return (
		<div className={`${cName}`}>
			<div className={classes.skillbox__text}>
				<p>{props.name}</p>
				<p>{props.p}%</p>
			</div>

			<div className={classes.skill}>
				<div className={classes.skill_level}></div>
			</div>
		</div>
	)
}

export default ProgressBar
