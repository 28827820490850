import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { MenuContextProvider } from './components/store/menu-context'
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
		<MenuContextProvider>
			<App />
		</MenuContextProvider>
)
